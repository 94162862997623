<template>
  <div>
    <h2 class="mb-4">Настройки</h2>

    <v-sheet class="pa-4" elevation="3">
      <v-form autocomplete="off" @submit.prevent="save">
        <div class="text-h6 mb-3">Количество строк в таблицах</div>

        <v-text-field v-model="settings.guestLimit" label="Гость" required />
        <v-text-field v-model="settings.noSubscriptionLimit" label="Пользователь без подписки" required />

        <div class="text-h6 mb-3">Количество скрытых строк в таблицах</div>

        <v-text-field v-model="settings.offset" label="Пользователь без подписки" required />

        <div class="text-h6 mb-3">Прочее</div>

        <v-text-field
          v-model="settings.noSubscriptionFavoritesLimit"
          label="Лимит акций в избранном (без подписки)"
          required
        />

        <div class="mt-4 text-right">
          <v-btn type="submit" :loading="loading" color="accent">Сохранить</v-btn>
        </div>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  data() {
    return {
      loading: false,
      settings: {
        guestLimit: "",
        noSubscriptionLimit: "",
        noSubscriptionFavoritesLimit: "",
        offset: ""
      }
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    async fetchSettings() {
      try {
        this.settings = await this.$axios.$get("settings");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Настройки не получены: ${html}`);
      }
    },
    async save() {
      this.loading = true;

      try {
        this.settings = await this.$axios.$patch("settings", { ...this.settings });

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не обновлено: ${html}`);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
